import React, { useEffect } from 'react';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './assets/css/styles.css';
import faviconImage from './assets/img/favicon.svg';

function App() {

  useEffect(() => {
    // Update the page title
    document.title = "josephnkm";

    // Update the favicon
    const favicon = document.querySelector("link[rel='icon']") || document.createElement("link");
    favicon.rel = "icon";
    favicon.href = faviconImage; // Provide the correct path to your new favicon
    document.head.appendChild(favicon);
  }, []);

  return (
    <div className="App">
      <Header />
      <main className="l-main">
        <Home />
        <About />
        <Skills />
        {/* <Portfolio /> */}
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Contact = () => {
  // Yup schema for validation
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, 'Name must be at least 2 characters')
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    message: Yup.string()
      .min(10, 'Message must be at least 10 characters')
      .required('Message is required'),
  });

  // Function to handle form submission with retry logic
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let attempts = 0;
    const maxAttempts = 3;
    const retryInterval = 3 * 60 * 1000; // 3 minutes in milliseconds

    const sendRequest = async () => {
      try {
        const response = await fetch('https://quiz-5v4p.onrender.com/josephnakarmi/message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          alert('Message sent successfully!');
          resetForm(); // Reset form after successful submission
        } else {
          throw new Error('Failed to send message. Please try again.');
        }
      } catch (error) {
        attempts += 1;
        console.error(`Attempt ${attempts}:`, error);

        if (attempts < maxAttempts) {
          console.log(`Retrying in 3 minutes...`);
          setTimeout(sendRequest, retryInterval);
        } else {
          alert('Failed to send message after multiple attempts. Please try again later.');
        }
      } finally {
        if (attempts >= maxAttempts) {
          setSubmitting(false); // End loading state after final attempt
        }
      }
    };

    sendRequest();
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section-title">Contact</h2>
      <div className="contact__container bd-grid">
        <div className="contact__info">
          <h3 className="contact__subtitle">EMAIL</h3>
          <span className="contact__text">josephnkm@gmail.com</span>
          <h3 className="contact__subtitle">ADDRESS</h3>
          <span className="contact__text">Hetauda, Nepal</span>
        </div>

        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form className="contact__form">
              <div className="contact__inputs">
                <div>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="contact__input"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="contact__input"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div>
                <Field
                  as="textarea"
                  name="message"
                  placeholder="Message"
                  cols="0"
                  rows="10"
                  className="contact__input"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="contact__buttons">
                <button
                  type="submit"
                  className="contact__button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
                <button
                  type="button"
                  className="contact__button contact__button--reset"
                  onClick={() => resetForm()}
                >
                  Reset
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default Contact;

import React from 'react';
import softwareDevelopment from '../assets/img/software-development.jpg';

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <h2 className="section-title">Skills</h2>
      <div className="skills__container bd-grid">
        <div className="skills__box">
          <h3 className="skills__subtitle">Development</h3>
          <span className="skills__name">Java</span>
          <span className="skills__name">Spring</span>
          <span className="skills__name">JavaScript</span>
          <span className="skills__name">React.js</span>
          <h3 className="skills__subtitle">Design</h3>
          <span className="skills__name">3D Modeling</span>
          <span className="skills__name">After Effects</span>
          <span className="skills__name">Photoshop</span>
        </div>
        <div className="skills__img">
          <img src={softwareDevelopment} alt="Skills" />
        </div>
      </div>
    </section>
  );
}

export default Skills;

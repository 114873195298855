import React from 'react';
import joseImage from '../assets/img/jose.png';


function Home() {
  return (
    <section className="home" id="home">
      <div className="home__container bd-grid">
        <h1 className="home__title"><span>NA</span><br />MAS<br />TE.</h1>
        <div className="home__scroll">
          <a href="#about" className="home__scroll-link"><i className='bx bx-up-arrow-alt'></i>Scroll down</a>
        </div>
        <img src={joseImage} alt="" className="home__img" />
      </div>
    </section>
  );
}

export default Home;

import React from 'react';
import joseph from '../assets/img/joseph.jpg';


const About = () => {
  const yearsOfExperience = new Date().getFullYear() - 2018;
  return (
    <section className="about section" id="about">
      <h2 className="section-title">About</h2>
      <div className="about__container bd-grid">
        <div className="about__img">
          <img src={joseph} alt="Joseph Nakarmi" />
        </div>
        <div>
          <h2 className="about__subtitle">Joseph Nakarmi</h2>
          <span className="about__profession">Software Developer</span>
          <p className="about__text">
            With {yearsOfExperience} years of industry experience in Software Development, I've worked on diverse projects spanning GIS, ERP, Insuretech and Fintech. Currently, I'm part of
            <a href="https://www.verisk.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#730ce9', textDecoration: 'none', marginLeft: '5px' }}>Verisk</a>,
            where I continue to develop my expertise and contribute as a Senior Software Engineer.
          </p>
          <div className="about__social">
            <a href="https://www.linkedin.com/in/joseph-nakarmi-8106b215a" className="about__social-icon"><i className='bx bxl-linkedin'></i></a>
            <a href="https://github.com/josephnkm" className="about__social-icon"><i className='bx bxl-github'></i></a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

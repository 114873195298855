import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer section">
      <div className="footer__container bd-grid">
        <div className="footer__data">
          <h2 className="footer__title">JOSEPH NAKARMI</h2>
          <p className="footer__text">Personal Website</p>
        </div>
        <div className="footer__data">
          <h2 className="footer__title">FOLLOW</h2>
          <a href="https://facebook.com/josephnkm" className="footer__social" target="_blank" rel="noopener noreferrer">
            <i className='bx bxl-facebook'></i>
          </a>
          <a href="https://instagram.com/josephnkm" className="footer__social" target="_blank"><i className='bx bxl-instagram'></i></a>
          <a href="https://twitter.com/josephnakarmi" className="footer__social" target="_blank"><i className='bx bxl-twitter'></i></a>
        </div>
      </div>
      <div className="copyright">
        <p>COPYRIGHT &copy; {year} JOSEPH NAKARMI</p>
      </div>
    </footer>
  );
}

export default Footer;
